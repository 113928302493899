/* eslint-disable no-undef */
<template>
  <div>
    <vs-row vs-type="flex" vs-justify="center">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-xs="12" vs-w="6">
        <img src="images/logo-uncierre-514x114.png" alt="Logo Uncierre" width="300">
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-xs="12" vs-w="6">
        <ul class="list-uncierre text-right">
          <li>Administra,agiliza,</li>
          <li>y facilita <strong>tus cierres.</strong></li>
        </ul>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-xs="12" vs-w="12">
        <vs-divider position="center">
        </vs-divider>
      </vs-col>
    </vs-row>
    <form-wizard
      color="#7C7C7C"
      shape="circle"
      :title="null"
      :subtitle="null"
      back-button-text="Regresar"
      next-button-text="Siguiente"
      finishButtonText="Crear cuenta"
      error-color="#22C0FF"
      >
    
      <tab-content
      class="mb-5"
      icon-pack="fas"
      icon="fas fa-user-circle"
      :before-change="userInformationStep"
      >

      <vs-row vs-type="flex" vs-justify="center">
        <vs-col vs-type="" vs-justify="center" vs-align="center" vs-xs="12" vs-w="10">
          <h2 class="customHeader mt-3 text-center mb-4">Usuario administrador</h2>
          <div class="customMargin">
          <div class="customMargin2">
            <h4 class="customSubtitle">
              A continuación ingresa los datos de la persona encargada de
              liderar a todo el equipo comercial, este usuario poseerá todos
              los permisos máximos en la plataforma.
            </h4>
          </div>
          <vs-row vs-type="flex" vs-justify="center">
            <vs-col vs-type="" vs-justify="center" vs-align="center" vs-xs="12" vs-w="6">
              <vs-input
                label-placeholder="Nombres"
                type="text"
                v-validate="'required|min:3|max:10|alpha'"
                name="nombre"
                v-model="userName"
                class="w-full p-2"
                required="true"
                />
              <div
                class="text-info text-sm"
                v-show="errors.has('nombre')"
                >{{ errors.first("nombre") }}
              </div>
            </vs-col>
            <vs-col vs-type="" vs-justify="center" vs-align="center" vs-xs="12" vs-w="6">
              <vs-input
                type="text"
                label-placeholder="Apellidos"
                v-validate="'required|min:3|max:20|alpha'"
                name="apellidos"
                v-model="userLastName"
                class="w-full p-2"
                />
              <div
                class="text-info text-sm"
                v-show="errors.has('apellidos')"
                >{{ errors.first("apellidos") }}
              </div>
            </vs-col>
          </vs-row>
          <vs-row vs-type="flex" vs-justify="center">
            <vs-col vs-type="" vs-justify="center" vs-align="center" vs-xs="12" vs-w="3">
              <v-select
                label="label"
                v-model="userCountryArea"
                :options="countryAreas"
                class="bg-white w-full p-2 mt-6"
                ></v-select>
            </vs-col>
            <vs-col vs-type="" vs-justify="center" vs-align="center" vs-xs="12" vs-w="3">
              <vs-input
                type="tel"
                label-placeholder="Telefono"
                v-validate="'required|min:8'"
                name="teléfono"
                v-model="userPhone"
                class="w-full p-2"
                />
              <span
                class="text-info text-sm"
                v-show="errors.has('teléfono')"
                >{{ errors.first("teléfono") }}</span
                >
            </vs-col>
            <vs-col vs-type="" vs-justify="center" vs-align="center" vs-xs="12" vs-w="6">
              <vs-input
                type="email"
                label-placeholder="Email"
                v-validate="'required|email'"
                name="correo"
                v-model="userEmail"
                class="w-full p-2"
                />
              <span
                class="text-info text-sm"
                v-show="errors.has('correo')"
                >{{ errors.first("correo") }}</span
                >
              <span class="text-danger text-sm" v-show="emailError"
                >Correo electrónico ya se encuentra registrado</span
                >
            </vs-col>
          </vs-row>
          <vs-row vs-type="flex" vs-justify="center">
            <vs-col vs-type="" vs-justify="center" vs-align="center" vs-xs="12" vs-w="6">
              <vs-input
                v-model="userPassword"
                label-placeholder="Contraseña"
                v-validate="'required|min:8|max:20'"
                name="contraseña"
                ref="contrasena"
                type="password"
                class="w-full p-2"
                />
              <span
                class="text-info text-sm"
                v-show="errors.has('contraseña')"
                >{{ errors.first("contraseña") }}</span
                >
            </vs-col>
            <vs-col vs-type="" vs-justify="center" vs-align="center" vs-xs="12" vs-w="6">
              <vs-input
                v-model="userRepeatPassword"
                label-placeholder="confirmación de contraseña"
                v-validate="'required|confirmed:contrasena'"
                name="confirmación de contraseña"
                type="password"
                class="w-full p-2"
                />
              <span
                class="text-info text-sm"
                v-show="errors.has('confirmación de contraseña')"
                >{{ errors.first("confirmación de contraseña") }}</span
                >
            </vs-col>
          </vs-row>
          <div class="mt-8 mb-8 customSubtitle">
            <vs-checkbox
              color="success"
              v-validate="'required'"
              vs-value="Acepto los términos y condiciones expuestos en el documento."
              name="terminos"
              v-model="terms"
              >Acepto los términos y condiciones.</vs-checkbox
              >
            <router-link
              class="cLink"
              :to="{ name: 'terms' }"
              target="_blank"
              >Puede conocer los detalles aquí.</router-link
              >
          </div>
          </div>
          <div class="customMargin">
          <vs-alert
            :active="warningFirstStep"
            color="#fff"
            icon-pack="fas"
            icon="fa fa-warning"
            class="mt-5 text-danger"
            >Verifique los campos ingresados.</vs-alert
            >
          </div>

        </vs-col>
      </vs-row>
      </tab-content>

      <tab-content
      class="mb-5"
      icon-pack="fas"
      icon="fas fa-building"
      :before-change="developerInformationStep"
      >

      <vs-row vs-type="flex" vs-justify="center">
        <vs-col vs-type="" vs-justify="center" vs-align="center" vs-xs="12" vs-w="10">
            <h2 class="customHeader text-center mt-4">Datos de constructora</h2>
            <div class="customMargin">
            <div class="customMargin2">
              <h4 class="customSubtitle">
                Ingresa todos los detalles relacionados a la constructora, los
                datos ingresados en esta sección son públicos y ayudaran a tus
                clientes a conocerte más.
              </h4>
            </div>
            <vs-row vs-type="flex" vs-justify="center">
              <vs-col vs-type="" vs-justify="center" vs-align="center" vs-xs="12" vs-w="6">
                <p class="ml-2 subField">País de origen:</p>
                <v-select
                  class="areaCodeSelectorClient2 w-full p-2"
                  label-placeholder="Seleccione pais de origen..."
                  v-model="developerCountry"
                  label="name"
                  :options="countries"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  />
              </vs-col>
              <vs-col vs-type="" vs-justify="center" vs-align="center" vs-xs="12" vs-w="6">
                <vs-input
                  label-placeholder="Nombre comercial"
                  ref="NombreDesarrolladora"
                  name="nombre comercial"
                  v-validate="'required|min:3'"
                  v-model="developerName"
                  class="w-full p-2"
                  />
                <span
                  class="text-info text-sm"
                  v-show="errors.has('nombre comercial')"
                  >{{ errors.first("nombre comercial") }}</span
                  >
              </vs-col>
            </vs-row>
            <vs-row vs-type="flex" vs-justify="center">
              <vs-col vs-type="" vs-justify="center" vs-align="center" vs-xs="12" vs-w="6">
                <template>
                  <v-select
                    color="warning"
                    success
                    label-placeholder="Código de área"
                    class="areaCodeSelector3 mt-5 p-2"
                    :options="countryAreas"
                    v-validate="'required'"
                    name="Área"
                    label="label"
                    v-model="developerContryArea"
                    ></v-select>
                </template>
                <span
                  class="text-info text-sm"
                  v-show="errors.has('Área')"
                  >{{ errors.first("Área") }}</span
                  >
              </vs-col>
              <vs-col vs-type="" vs-justify="center" vs-align="center" vs-xs="12" vs-w="6">
                <vs-input
                  v-validate="'required|min:8'"
                  label-placeholder="El numero general de pbx"
                  name="teléfono comercial"
                  type="tel"
                  v-model="developerPhone"
                  class="w-full p-2"
                  />
                <span
                  class="text-info text-sm"
                  v-show="errors.has('teléfono comercial')"
                  >{{ errors.first("teléfono comercial") }}</span
                  >
              </vs-col>
            </vs-row>
            <vs-row vs-type="flex" vs-justify="center">
              <vs-col vs-type="" vs-justify="center" vs-align="center" vs-xs="12" vs-w="12">
                <vs-input
                  v-validate="'required|email'"
                  label-placeholder="Ejemplo: info@tudesarrolladora.com"
                  name="correo contacto"
                  type="email"
                  v-model="developerEmail"
                  class="w-full p-2"
                  />
                <span
                  class="text-info text-sm"
                  v-show="errors.has('correo contacto')"
                  >{{ errors.first("correo contacto") }}</span
                  >
              </vs-col>
            </vs-row>
            </div>
            <div class="customMargin">
            <vs-alert
              :active="warningSecondStep"
              color="#fff"
              icon-pack="fas"
              icon="fa fa-warning"
              class="mt-5 text-danger"
              >Verifique los campos ingresados.</vs-alert
              >
            </div>

        </vs-col>
      </vs-row>
      </tab-content>

      <tab-content
      class="mb-5"
      icon-pack="fas"
      icon="fas fa-credit-card"
      :before-change="subscriptionStep"
      >
        <vs-row vs-type="flex" vs-justify="center">
          <vs-col vs-type="" vs-justify="center" vs-align="center" vs-xs="12" vs-w="10">
              <h2 class="customHeader">Pago de suscripción</h2>
              <div class="customMargin">
              <div class="customMargin2">
              <h4 class="customSubtitle">
              Utilice nuestro procesador de pago para la suscripción, flattlo
              no almacena Información de su tarjeta.
              </h4>
              </div>
              <vs-divider color="info">Planes disponibles</vs-divider>
              <br />
              <div class="vx-row mb-1 cardArea gap-4 justify-center">
              <div class="vx-col sm:w-full md:w-2/5 w-full mb-2">
              <vx-card
                class="focusCard cVcard"
                align="center"
                title-color="#000"
                content-color="#000"
                card-background="white"
                >
                <ul class="centerx">
                  <li>
                    <vs-radio
                      v-model="suscriptionType"
                      color="warning"
                      class="mb-3"
                      vs-value="testers"
                      selected
                      >
                      <h3>Flattlo Testers - $69/Mes x Proyecto</h3>
                    </vs-radio>
                  </li>
                </ul>
                <p class="mb-2 planDetail">
                  Nuestro plan
                  <strong>Flattlo Testers</strong> incluye el acceso a
                  cantidad ilimitada de vendedores y cotizaciones generadas,
                  este plan incluye 60 días gratis.
                </p>
                <p>
                  *Este plan no incluye migraciones y generación de contenido.
                </p>
                <div v-show="false">
                  <button
                    style="
                    background-color: #6772e5;
                    color: #fff;
                    padding: 8px 12px;
                    border: 0;
                    border-radius: 4px;
                    font-size: 1em;
                    "
                    id="checkout-button-FlattloEarly"
                    role="link"
                    >
                  Checkout
                  </button>
                </div>
                <div id="error-message"></div>
              </vx-card>
              </div>
              </div>
              </div>
              <div class="customMargin mt-2">
              <vs-alert
              :active="warningThirdStep"
              color="#fff"
              icon-pack="fas"
              icon="fa fa-warning"
              class="mt-5 text-warning"
              >Necesita suscribir una tarjeta para la creacion de su
              cuenta.
              </vs-alert
              >
              </div>
          </vs-col>
        </vs-row>
      </tab-content>


    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";
import router from "../../router";

import countryApi from "../../services/country";
import { roleApi, accountApi } from "../../modules/users/services";
import { developerApi } from "../../modules/developer/services";
import { sentryCaptureException } from "../../helpers/Sentry";

export default {
  data() {
    return {
      terms: "",
      countries: [],
      countryAreas: [],
      userName: "",
      userLastName: "",
      userPhone: "",
      userEmail: "",
      userPassword: "",
      userRepeatPassword: "",
      userCountryArea: "",
      userRoleId: null,
      developerCountry: "",
      developerName: "",
      developerPhone: "",
      developerEmail: "",
      colorx: "#9ecc38",
      developerContryArea: null,
      suscriptionType: "frescos",
      warningSecondStep: false,
      warningFirstStep: false,
      warningThirdStep: false,
      backgroundLoading: "#1d324c",
      colorLoading: "#ffffff",
      loading: true,
      emailError: false,
      emailAfterSave: null,
      accountCreated: false
    };
  },

  components: {
    FormWizard,
    TabContent,
    "v-select": vSelect
  },
  async mounted() {
    const role = await roleApi.getByName("ADMIN");
    this.countries = await countryApi.getCountries();

    this.countryAreas = this.countries.map(c => {
      return {
        value: c.id,
        telephonePrefix: c.telephone_prefix,
        label: `${c.name} ${c.telephone_prefix}`
      };
    });

    this.userRoleId = role.id;

    this.userCountryArea = this.countryAreas.length
      ? this.countryAreas[0]
      : null;

    this.developerContryArea = this.countryAreas.length
      ? this.countryAreas[0]
      : null;
  },
  methods: {
    async firstStepIsValid() {
      const firstName = await this.$validator.validate("nombre");
      const lastName = await this.$validator.validate("apellidos");
      const phone = await this.$validator.validate("teléfono");
      const email = await this.$validator.validate("correo");
      const password = await this.$validator.validate("contraseña");
      const repeatPassword = await this.$validator.validate(
        "confirmación de contraseña"
      );
      const terms = await this.$validator.validate("terminos");
      if (
        !firstName ||
        !lastName ||
        !phone ||
        !email ||
        !password ||
        !repeatPassword ||
        !terms
      ) {
        return false;
      }

      return true;
    },
    async secondStepIsValid() {
      const name = await this.$validator.validate("nombre comercial");
      const phone = await this.$validator.validate("teléfono comercial");
      const email = await this.$validator.validate("correo contacto");
      if (!name || !phone || !email) {
        return false;
      }

      return true;
    },
    async userInformationStep() {
      const isValid = await this.firstStepIsValid();

      if (!isValid) {
        this.warningFirstStep = true;
        return false;
      }

      if (
        !this.accountCreated ||
        (this.accountCreated && this.userEmail !== this.emailAfterSave)
      ) {
        const accountsEmail = await accountApi.getByEmail(
          this.userEmail.trim()
        );
        if (accountsEmail.length) {
          this.emailError = true;
          return false;
        }
      }

      return true;
    },
    async developerInformationStep() {
      try {
        const isValid = await this.secondStepIsValid();

        if (!isValid) {
          this.warningSecondStep = true;
          return false;
        }

        if (
          !this.accountCreated ||
          (this.accountCreated && this.userEmail !== this.emailAfterSave)
        ) {
          const developer = await developerApi.createDeveloper(
            this.developerName,
            this.developerContryArea.telephonePrefix,
            this.developerPhone,
            this.developerCountry.id,
            this.developerEmail
          );

          const newAccount = await accountApi.createAccount(
            this.userName,
            this.userLastName,
            this.userCountryArea.telephonePrefix,
            this.userPhone,
            this.userEmail,
            null,
            this.userPassword,
            this.userRoleId
          );

          await accountApi.addAccountToDeveloper(newAccount.id, developer.id);

          this.emailAfterSave = newAccount.email;
          this.accountCreated = true;
        }

        return true;
      } catch (e) {
        sentryCaptureException(e, "create-account");
      }
    },
    subscriptionStep: function() {
      if (this.suscriptionType == "diamond") {
        this.openLoadingBackground();
        setTimeout(function() {
          // eslint-disable-next-line no-undef
          const stripe = Stripe("pk_test_BEbRIjhP65J586tWfEbPuWhv00q7HmWGyq");
          stripe
            .redirectToCheckout({
              customerEmail: this.userEmail,
              items: [{ plan: "price_1IiZtAE6EBynUW9OgcdbRGJI", quantity: 1 }],
              successUrl: "https://dash.flattlo.com/login",
              cancelUrl: "https://dash.flattlo.com/err-account-created"
            })
            .then(function(result) {
              if (result.error) {
                console.log(result.error.message);
              }
            });
        }, 3000);
      } else {
        router.push("Login");
        this.$vs.notify({
          time: 6000,
          title: "¡Super Fácil! 🎉",
          text: `Cuenta creada ${this.userName}, ahora puedes acceder a ${this.developerName}`,
          color: "success",
          iconPack: "feather",
          icon: "icon-check"
        });
      }
    },
    postUser(userID) {
      const url = "https://dev.flattlo.com/webhook/10/webhook/welcome";
      // create request object
      const request = new Request(url, {
        method: "POST",
        mode: "no-cors",
        body: userID,
        headers: {
          accept: "application/json;odata=verbose",
          contentType: "text/xml"
        }
      });

      // pass request object to `fetch()`
      fetch(request)
        .then(res => res.json())
        .then(res => console.log(res));
    },
    openLoadingBackground() {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: "rgb(66, 134, 245)"
      });
    },
  }
};
</script>

<style lang="scss">
.vue-country-select .dropdown {
  background: #fff !important;
  border-radius: 0.3em !important;
}
.vue-country-select .current {
  font-size: 2em !important;
}
body {
  background: #fff;
}
.subField {
  color: #fff;
  font-size: 0.2;
}

  input, .vs__dropdown-toggle  {
    border-radius: 30px;
    background: #F2F4F6;
    color:#111 !important;
  }

  .customHeader {
    font-weight: bold;
  }

  .customSubtitle {
    line-height: 25px;
    font-size: 15px;
    text-align: center;
  }

</style>
